<template>
	<div class="characterRecognition">
		<img src="../../assets/product/characterRecognition/1.png" class="headerimg" />

		<div class="serve">
			<div class="title">文字识别 OCR 简介</div>
			<div class="serveinfo">
				邦伲德·文字识别（Optical Character Recognition，OCR）基于邦伲德实验室世界领先的深度学习技术，将图片上的文字内容，智能识别成为可编辑的文本。OCR
				支持身份证、名片等卡证类和票据类的印刷体识别，也支持运单等手写体识别，支持提供定制化服务，可以有效地代替人工录入信息。
			</div>
		</div>

		<div class="characteristic">
			<div class="title">文字识别的特性</div>
			<div class="characteristiclist">
				<div class="characteristicinfo">
					<div class="characteristicheard">
						<img src="../../assets/product/characterRecognition/2.png" />
						<div class="characteristictext">准确率高</div>
					</div>
					<div class="text">
						文字识别 OCR 可自动从图片中定位并识别字段，印刷体的平均准确率可达90%以上，手写体的识别平均准确率高达85%以上，鲁棒性强
					</div>
				</div>
				<div class="characteristicinfo">
					<div class="characteristicheard">
						<img src="../../assets/product/characterRecognition/3.png" />
						<div class="characteristictext">稳定性强</div>
					</div>
					<div class="text">
						身份证识别、名片识别、营业执照识别服务已成功应用于微众银行、QQ、广点通等邦伲德内部核心业务，接受过海量用户和复杂场景的考验，各项反馈良好。
					</div>
				</div>
				<div class="characteristicinfo">
					<div class="characteristicheard">
						<img src="../../assets/product/characterRecognition/4.png" />
						<div class="characteristictext">场景丰富</div>
					</div>
					<div class="text">
						依托邦伲徳自研的 OCR 技术，涵盖了整个证件检测识别框架的所有核心算法，支持横向、竖向拍摄，适应透视畸变、光照不均、部分遮挡的情况，具备非常高的复杂环境可用性。
					</div>
				</div>
				<div class="characteristicinfo">
					<div class="characteristicheard">
						<img src="../../assets/product/characterRecognition/5.png" />
						<div class="characteristictext">应用广泛</div>
					</div>
					<div class="text">
						文字识别 OCR 提供手写体和印刷体的识别，除各类标准化的卡证识别外，也提供定制化的 OCR 识别，如各类票据或运单等的个性化识别，满足多样化的场景应用需要。
					</div>
				</div>
			</div>
		</div>

		<div class="adhibition">
			<div class="title">应用场景</div>
			<div class="adhibitioninfo top">
				<div class="adhibitiontitle">
					金融行业身份识别
				</div>
				<div class="adhibitiontext">
					身份证识别可广泛应用在银行、保险、证券行业等需要验证用户身份的场景中，帮助减少用户的信息输入，提升效率，提高用户体验。
				</div>
			</div>
			<div class="adhibitioninfo top2">
				<div class="adhibitiontitle">
					互联网行业身份识别
				</div>
				<div class="adhibitiontext">
					在视频直播或网约车场景中，可以通过身份证识别进行主播或乘客的身份认证，降低犯罪风险。
				</div>
			</div>
			<div class="adhibitionimg">
				<img src="../../assets/product/characterRecognition/6.png" />
			</div>
		</div>

		<div class="subproduct">
			<div class="cause">
				<div class="title">文字识别的子产品</div>
				<div class="causecardlist">
					<div class="causecardinfo">
						<div class="causecard">
							<img src="../../assets/product/characterRecognition/7.png" />
							<div class="title">通用文字识别</div>
							<div class="info">提供通用印刷体识别、通用手写体识别、英文识别等多种服务，可应用于随手拍扫描、纸质文档电子化、电商广告审核等多种场景。</div>
						</div>
						<div class="causecard">
							<img src="../../assets/product/characterRecognition/8.png" />
							<div class="title">卡证文字识别</div>
							<div class="info">
								提供身份证识别、银行卡识别、名片识别、营业执照识别等多种服务，可应用于用户注册、银行开户、交通出行、政务办事等多种场景。
							</div>
						</div>
						<div class="causecard">
							<img src="../../assets/product/characterRecognition/9.png" />
							<div class="title">通用文字识别</div>
							<div class="info">
								提供通用印刷体识别、通用手写体识别、英文识别等多种服务，可应用于随手拍扫描、纸质文档电子化、电商广告审核等多种场景。
							</div>
						</div>
					</div>
					<div class="causecardinfo">
						<div class="causecard">
							<img src="../../assets/product/characterRecognition/10.png" />
							<div class="title">汽车相关识别</div>
							<div class="info">
								提供驾驶证识别、行驶证识别、车牌识别、车辆 VIN 码识别等多种服务，可应用于车主身份认证、ETC 出行、违章识别、停车管理等多种场景。
							</div>
						</div>
						<div class="causecard">
							<img src="../../assets/product/characterRecognition/11.png" />
							<div class="title">行业文档识别</div>
							<div class="info">
								提供表单表格识别、体检报告识别、检验检查单识别、算式识别等多种行业文档识别服务，可应用于智能核保及理赔、试题批改等多种行业场景。
							</div>
						</div>
						<div class="causecard">
							<img src="../../assets/product/characterRecognition/12.png" />
							<div class="title">智能扫码 IS</div>
							<div class="info">
								提供二维码和条形码识别、条码信息查询 以及智能扫码 SDK，可应用于大图小码、一图多码、模糊识别、智能服务等多种场景。
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="case">
			<div class="title">客户案例</div>
			<div class="list top">
				<div class="card">
					<img src="../../assets/product/characterRecognition/13.png" />
					<div class="cardtitle">大众点评</div>
					<div class="info">
						通用印刷体OCR为护提供图片内容审核能力，有效识别图片中的不文明信息，大大减少了人工审核工作量。
					</div>
					<div class="bebottom"></div>
				</div>
				<div class="card">
					<img src="../../assets/product/characterRecognition/14.png" />
					<div class="cardtitle">顺丰速运</div>
					<div class="info">
						手写体 OCR 解决了顺丰运单手写体不易识别，人工录入耗时费力的问题，字段准确率达到98%。
					</div>
					<div class="bebottom"></div>
				</div>
				<div class="card">
					<img src="../../assets/product/characterRecognition/15.png" />
					<div class="cardtitle">快手</div>
					<div class="info">
						邦伲德身份证 OCR 产品帮助客户完成用户身份的自动审核，极大地减少了人工审核工作，降低了人力成本。
					</div>
					<div class="bebottom"></div>
				</div>
			</div>
			<div class="list">
				<div class="card">
					<img src="../../assets/product/characterRecognition/16.png" />
					<div class="cardtitle">中外运</div>
					<div class="info">
						运单 OCR 产品帮助客户完成业务单据的自动录入，使录入时间从分钟级降低为秒级，极大地提高信息录入效率。
					</div>
					<div class="bebottom"></div>
				</div>
				<div class="card">
					<img src="../../assets/product/characterRecognition/17.png" />
					<div class="cardtitle">OPPO</div>
					<div class="info">
						OPPO通过使用邦伲德的多种文字识别产品，丰富了内置软件的产品功能，有效提升用户粘性。。
					</div>
					<div class="bebottom"></div>
				</div>
				<div class="card">
					<img src="../../assets/product/characterRecognition/18.png" />
					<div class="cardtitle">微众银行</div>
					<div class="info">
						邦伲徳身份证、驾驶证 OCR 产品帮助微众银行完成用户身份审核及风控工作，为业务提供多重安全保障。
					</div>
					<div class="bebottom"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style scoped lang="less">
	.characterRecognition {
		.title {
			text-align: center;
			font-size: 36px;
			font-weight: 500;
			line-height: 54px;
		}

		.serve {
			margin-top: 162px;
			margin-bottom: 52px;

			.serveinfo {
				font-size: 24px;
				font-weight: 500;
				color: #616B80;
				line-height: 44px;
				max-width: 80%;
				margin: auto;
				margin-top: 52px;
			}
		}

		.characteristic {
			background-color: #F5F5F9;
			padding-top: 111px;
			padding-bottom: 125px;

			.characteristiclist {
				display: flex;
				width: 80%;
				margin: auto;
				margin-top: 100px;
				margin-bottom: 140px;
				justify-content: space-between;

				.characteristicinfo {
					width: 285px;

					.characteristicheard {
						text-align: center;
						font-size: 30px;
						font-weight: 500;
						line-height: 45px;

						img {
							height: 55px;
						}

						.characteristictext {
							margin-top: 36px;
							font-size: 30px;
							font-weight: 500;
							color: #333333;
							line-height: 45px;
						}
					}

					.text {
						text-align: center;
						margin-top: 20px;
						font-size: 24px;
						font-weight: 500;
						color: #616B80;
						line-height: 40px;
					}
				}
			}
		}

		.adhibition {
			width: 80%;
			margin: 96px auto;

			.adhibitioninfo {
				border-bottom: 1px solid #D3D3D3;
				padding-bottom: 30px;

				.adhibitiontitle {
					font-size: 37px;
					font-weight: 500;
					color: #2F7FFC;
					line-height: 56px;
				}

				.adhibitiontext {
					margin-top: 30px;
					font-size: 24px;
					font-weight: 400;
					color: #616B80;
					line-height: 48px;
				}
			}

			.adhibitionimg {
				margin-top: 52px;

				img {
					width: 100%;
				}
			}

			.top {
				margin-top: 83px;
			}

			.top2 {
				margin-top: 41px;
			}
		}

		.subproduct {
			background-color: #F5F5F9;
			padding-top: 92px;
			padding-bottom: 61px;

			.cause {
				margin: 44px auto;
				width: 80%;

				.causecardlist {
					.causecardinfo {
						margin-top: 115px;
						display: flex;
						justify-content: space-between;
						margin-top: 96px;

						.causecard {
							background-color: #fff;
							text-align: center;
							width: 430px;
							height: 270px;
							box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
							border-radius: 10px;
							border: 1px solid #e8e8e8;
							padding: 45px 28px;
							box-sizing: border-box;

							.title {
								font-size: 24px;
								font-family: Medium;
								font-weight: 500;
							}

							.info {
								font-size: 20px;
								font-family: Medium;
								font-weight: 500;
								color: #9e9e9e;
								margin-top: 14px;
							}

							img {
								margin: 0 auto;
								width: 88px;
								margin-top: -90px;
								position: relative;
								display: block
							}
						}
					}
				}
			}
		}

		.case {
			margin-top: 98px;
			padding-bottom: 44px;
			.list {
				margin:55px auto;
				display: flex;
				width: 80%;
				justify-content: space-between;

				.card:hover {
					.bebottom {
						border-radius: 0 0 20px 20px;
						width: 338px;
						height: 17px;
						position: absolute;
						margin-top: 22px;
						margin-left: 18px;
						background-color: #2f7ffc;
					}
				}

				.card {
					cursor: pointer;
					width: 430px;
					height: 304px;
					box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
					border-radius: 10px;
					border: 1px solid #e8e8e8;
					padding: 40px 35px;
					box-sizing: border-box;

					.cardtitle {
						font-size: 24px;
						font-weight: 500;
						line-height: 36px;
						margin-top: 23px;
					}

					.info {
						margin-top: 9px;
						font-size: 20px;
						font-weight: 400;
						color: #9e9e9e;
						line-height: 32px;
					}

					img {
						width: 71px;
					}
				}
			}
		}
	}
</style>
